import { Model } from "@vuex-orm/core";
import Dossier from "./Dossier";

export default class NotificationLog extends Model {
  static get entity() {
    return "notificationLogs";
  }

  static fields() {
    return {
      id: this.attr(null),
      phase: this.attr(null),
      dossierId: this.attr(null),
      dossier: this.belongsTo(Dossier, "dossierId"),
      actionDate: this.attr(null),
      customActionDate: this.attr(null),
      actionData: this.attr(null),

      readStatusAgency: this.attr(null),
      readStatusPartner: this.attr(null),
      readStatusClient: this.attr(null),
    };
  }
}
